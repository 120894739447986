
import { useState } from 'react';
import '../styles/Soins.css';

export default function Settings(props) {

  const [lsmsName, setLsmsName] = useState();
  const [lsmsId, setLsmsId] = useState();
  const [hasClicked, setHasClicked] = useState(false);


  return (
    <>
      <div className='oneLiner'>
        <div className='reportInput'>
          <label htmlFor="lsms-name">Votre Prénom et Nom :</label>
          <input
            id="lsms-name"
            type="text"
            placeholder="Ex: John Doe"
            onChange={(e) => setLsmsName(e.target.value)}
            defaultValue={
              window.localStorage.getItem('lsms-name') || ''
            }
          />
        </div>
        <p className='save'
          onClick={() => {
            window.localStorage.setItem('lsms-name', lsmsName);
            setHasClicked(true);
          }}
        >{hasClicked ? 'Enregistré !' : 'Enregistrer pour plus tard'}</p>
      </div>
      <div className='oneLiner'>
        <div className='reportInput'>
          <label htmlFor="lsms-id">Votre ID :</label>
          <input
            id="lsms-id"
            type="text"
            placeholder="Ex: John Doe"
            onChange={(e) => setLsmsId(e.target.value)}
            defaultValue={
              window.localStorage.getItem('lsms-id') || ''
            }
          />
        </div>
        <p className='save'
          onClick={() => {
            window.localStorage.setItem('lsms-id', lsmsId);
            setHasClicked(true);
          }}
        >{hasClicked ? 'Enregistré !' : 'Enregistrer pour plus tard'}</p>
      </div>
    </>
  )
}